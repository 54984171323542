
import { defineComponent, nextTick } from 'vue';
import PageHeader from '../components/PageHeader.vue'
import BookingDetails from '../components/BookingDetails.vue'

interface BookingData {
  location: string;
}

export default defineComponent({
  name: 'Booking',
  data(): BookingData {
    return {
      location: window.location.pathname.split("/")[1]
    }
  },
  components: {
    PageHeader,
    BookingDetails
  },
  mounted() {
    const resovaAccount = this.location === 'middlesbrough' ? 'redrobotsystems' : 'pehexham'
    nextTick(function() {
      (function(d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js: any = d.createElement(s); js.id = id;
      js.src = "https://" + resovaAccount + ".resova.co.uk/widget";
      fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'resova-pi'));
    });
    const w = window as any;
    w.fbq('track', 'ViewContent');
  }
});
